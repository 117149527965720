import React, { useRef, useState } from 'react';
import { Button, Drawer, Form, FormsOnSubmit, HorizontalGroup } from '@grafana/ui';
import useConfirm from '../../hooks/useConfirm';
import UpdateIsDirty from '../utils/UpdateIsDirty';
import DataflowForm, { DataflowFormValues } from './DataflowForm';
import { POST } from '../../client';
import _ from 'lodash';
import { getAppEvents } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';
import { AbortError } from 'fork-ts-checker-webpack-plugin/lib/utils/async/abort-error';

interface DataflowAddDrawerProps {
  onClose: () => void;
}

const DataflowAddDrawer: React.FC<DataflowAddDrawerProps> = ({ onClose }) => {
  const [testResult, setTestResult] = useState<string>('');
  const [ConfirmModalComponent, showConfirm] = useConfirm({
    title: 'Unsaved Changes',
    body: 'You have unsaved changes, are you sure you want to discard it?',
    confirmText: 'Discard',
    onConfirm: onClose,
  });
  const isDirtyRef = useRef(false);

  const handleSubmit: FormsOnSubmit<DataflowFormValues> = async (data) => {
    try {
      if (!data.source || !data.sink) {
        return;
      }

      data.processes.forEach((process, index) => {
        process.sequence = index + 1;
      });

      const { error } = await POST('/api/dataflows', {
        body: {
          name: data.name,
          description: data.description,
          source: data.source,
          sourceArgs: data.sourceArgs,
          processes: data.processes,
          sink: data.sink,
          sinkArgs: data.sinkArgs,
        },
      });

      const appEvents = getAppEvents();

      if (!error) {
        appEvents.publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Dataflow added successfully'],
        });
        onClose();
      } else {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Dataflow add failed: ${error.message}`],
        });
      }
    } catch (err) {
      const appEvents = getAppEvents();
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`Dataflow add failed: ${err}`],
      });
    }
  };

  const handleTest = async (values: DataflowFormValues) => {
    const control = new AbortController();

    const t = setTimeout(() => {
      control.abort();
    }, 10000);

    try {
      setTestResult('Running...');
      if (!values.source || !values.sink) {
        return;
      }

      values.processes.forEach((process, index) => {
        process.sequence = index + 1;
      });

      const { data, error } = await POST('/api/dataflows/test', {
        body: {
          name: values.name,
          description: values.description,
          source: values.source,
          sourceArgs: values.sourceArgs,
          processes: values.processes,
          sink: values.sink,
          sinkArgs: values.sinkArgs,
        },
        signal: control.signal,
      });
      if (error) {
        setTestResult(`${error}`);
        return;
      }
      if (typeof data !== 'string') {
        if (_.isObject(data) && 'error' in data) {
          // @ts-ignore
          setTestResult(result.error);
        } else {
          setTestResult(JSON.stringify(data));
        }
      } else {
        setTestResult(data);
      }
    } catch (err) {
      if (err instanceof AbortError) {
        setTestResult('Timeout');
      } else {
        setTestResult(`${err}`);
      }
    } finally {
      clearTimeout(t);
    }
  };

  const handleClose = () => {
    if (!isDirtyRef.current) {
      onClose();
      return;
    }
    showConfirm();
  };

  return (
    <Drawer title="Add Dataflow" size="md" onClose={handleClose} scrollableContent>
      <>
        <Form
          defaultValues={{
            name: '',
            description: '',
            source: null,
            sourceArgs: {},
            processes: [],
            sink: null,
            sinkArgs: {},
          }}
          className="p-4"
          onSubmit={handleSubmit}
        >
          {(props) => (
            <>
              <UpdateIsDirty isDirtyRef={isDirtyRef} isDirty={props.formState.isDirty} />
              <DataflowForm {...props} testResult={testResult} />
              <p className="text-sm opacity-70">
                {`Test takes first message from 'send()' or an exception. If neither happens in 10 seconds, console will
                display 'Timeout'.`}
              </p>
              <HorizontalGroup spacing="md">
                <Button
                  type="button"
                  onClick={async () => {
                    await handleTest(props.getValues());
                  }}
                  variant="success"
                >
                  Test
                </Button>
                <Button type="submit">Add</Button>
              </HorizontalGroup>
            </>
          )}
        </Form>
        {ConfirmModalComponent}
      </>
    </Drawer>
  );
};

export default DataflowAddDrawer;
