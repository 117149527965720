import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './components/SimplePanel';
import '../../../styles/dist/index.css';

// @ts-ignore
export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions((builder) => {
  return builder.addRadio({
    path: 'view',
    defaultValue: 'channels',
    name: 'View',
    settings: {
      options: [
        {
          value: 'channels',
          label: 'Channels',
        },
        {
          value: 'dataflows',
          label: 'Dataflows',
        },
        {
          value: 'functions',
          label: 'Functions',
        },
      ],
    },
  });
  // return builder
  //   .addTextInput({
  //     path: 'text',
  //     name: 'Simple text option',
  //     description: 'Description of panel option',
  //     defaultValue: 'Default value of text input option',
  //   })
  //   .addBooleanSwitch({
  //     path: 'showSeriesCount',
  //     name: 'Show series counter',
  //     defaultValue: false,
  //   })
  //   .addRadio({
  //     path: 'seriesCountSize',
  //     defaultValue: 'sm',
  //     name: 'Series counter size',
  //     settings: {
  //       options: [
  //         {
  //           value: 'sm',
  //           label: 'Small',
  //         },
  //         {
  //           value: 'md',
  //           label: 'Medium',
  //         },
  //         {
  //           value: 'lg',
  //           label: 'Large',
  //         },
  //       ],
  //     },
  //     showIf: (config) => config.showSeriesCount,
  //   });
});
