import React, { useEffect, useState } from 'react';
import { Badge, Button, Card } from '@grafana/ui';
import { Dataflow, FunctionDataType, SinkFunction, TypedFunction } from '../../types';
import DataflowEditDrawer from './DataflowEditDrawer';
import { DELETE, GET } from '../../client';
import _ from 'lodash';
import useConfirm from '../../hooks/useConfirm';
import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';

interface DataflowListItemProps {
  dataflow: Dataflow;
}

const DataflowListItem: React.FC<DataflowListItemProps> = ({ dataflow }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sinkOutputType, setSinkOutputType] = useState<FunctionDataType | null>(null);
  const [ConfirmComponent, handleDelete] = useConfirm({
    title: `Delete dataflow (${dataflow.name})`,
    body: 'Are you sure you want to delete this dataflow?',
    confirmText: 'Delete',
    onConfirm: async () => {
      const appEvents = getAppEvents();
      try {
        const { error } = await DELETE('/api/dataflows/{id}', {
          params: {
            path: {
              id: dataflow.id,
            },
          },
        });

        if (!error) {
          appEvents.publish({
            type: AppEvents.alertSuccess.name,
            payload: [`Delete dataflow (${dataflow.name}) Successfully`],
          });
        } else {
          appEvents.publish({
            type: AppEvents.alertError.name,
            payload: [`Delete dataflow (${dataflow.name}) failed: ${error.message}`],
          });
        }
      } catch (err) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Delete dataflow (${dataflow.name}) failed: ${err}`],
        });
      }
    },
  });

  useEffect(() => {
    GET('/api/sink-functions/{id}', {
      params: {
        path: {
          id: dataflow.sinkId,
        },
      },
    }).then(({ data }) => {
      if (!data) {
        return;
      }
      setSinkOutputType(data.outputType);
    });
  }, [dataflow]);

  return (
    <Card>
      <Card.Heading>
        {dataflow.name}{' '}
        <Card.Tags className="flex space-x-2 items-center">
          {sinkOutputType && <Badge text={_.capitalize(sinkOutputType)} color="blue" />}
          {dataflow.processes.length > 0 && <Badge text="Processed" color="orange" />}
          <Badge text="Running" color="green" />
        </Card.Tags>
      </Card.Heading>
      <Card.Description>{dataflow.description}</Card.Description>
      {/* <Card.Meta>{[`Host: ${datasource.host}`, `Port: ${datasource.port}`]}</Card.Meta> */}

      <Card.Actions>
        <Button key="settings" variant="secondary" icon="edit" onClick={() => setIsOpen(true)}>
          Edit
        </Button>
        <Button key="explore" variant="destructive" icon="trash-alt" onClick={() => handleDelete()}>
          Delete
        </Button>
      </Card.Actions>
      {isOpen && <DataflowEditDrawer dataflow={dataflow} onClose={() => setIsOpen(false)} />}
      {ConfirmComponent}
      {/* {isOpen && <DatasourceEditDrawer datasource={datasource} onClose={() => setIsOpen(false)} onEdited={onEdited} />} */}
    </Card>
  );
};

export default DataflowListItem;
