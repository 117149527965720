import { DataflowProcessInput, SinkFunctionInput, SourceFunctionInput, TypedFunction } from '../../types';
import { Button, FormAPI } from '@grafana/ui';
import React, { useEffect, useMemo } from 'react';
import { DataflowFormValues } from './DataflowForm';
import DataflowAddFromExistingFunctionButton from './DataflowAddFromExistingFunctionButton';

type DataflowNewFunctionProps =
  | {
      typedFunctions: TypedFunction[];
    } & (
      | {
          type: 'Source';
          setValue: (value: SourceFunctionInput | number) => void;
        }
      | {
          type: 'Process';
          getValues: FormAPI<DataflowFormValues>['getValues'];
          setValue: (value: DataflowProcessInput) => void;
        }
      | {
          type: 'Sink';
          setValue: (value: SinkFunctionInput | number) => void;
        }
    );

const SOURCE_FUNCTION_TEMPLATE = `
from typing import Generator, Callable

def handle(args: dict, subscribe: Callable[[dict], Generator]) -> Generator:
    # TODO: implement your function here
    # yield data every time you want to send data to the next function
    # e.g.
    # while True:
    #     data = get_data()
    #     yield data
`.trim();

const PROCESS_FUNCTION_TEMPLATE = `
from typing import Iterable, Generator

def handle(data: Iterable, args: dict) -> Generator:
    # TODO: implement your function here
    # loop over iterable to get data yield from previous function
    # yield data every time you want to send data to the next function
    # e.g.
    # for d in data:
    #     yield process(d)
`.trim();

const SINK_FUNCTION_TEMPLATE = `
from typing import Iterable, Callable, List

def handle(
    data: Iterable,
    args: dict,
    register: Callable[[List[dict]], None],
    send: Callable[[dict], None],
):
    # TODO: implement your function here
    # loop over iterable to get data yield from previous function
    # call register() to register channels and upsert attributes
    # call send() to send data to grafana
    # e.g.
    # register([{ 'name': 'ch1' }, { 'name': 'ch2', 'channel_attributes': {}, 'plotting_attributes': {}])
    # import time
    # for d in data:
    #     send({
    #         'time': [time.time()], # required, [float, float, ...] | np.ndarray[float]
    #         'ch1': [d['ch1']], # [float, float, ...] | np.ndarray[float]
    #         'ch2': [d['ch2']], # [float, float, ...] | np.ndarray[float]
    #     })
`.trim();

const DataflowNewFunction: React.FC<DataflowNewFunctionProps> = ({ typedFunctions, type, setValue, ...props }) => {
  const handleNewInlineFunction = () => {
    switch (type) {
      case 'Source':
        setValue({
          outputType: 'TIMESERIES',
          function: {
            name: 'Inline Source',
            description: '',
            schema: {},
            code: SOURCE_FUNCTION_TEMPLATE,
            pinLevel: 'UNPINNED',
          },
        });
        break;
      case 'Process':
        const existingProcesses = (props as Extract<DataflowNewFunctionProps, { type: 'Process' }>).getValues(
          'processes'
        );
        setValue({
          sequence: existingProcesses.length + 1,
          args: {},
          function: {
            inputType: 'TIMESERIES',
            outputType: 'TIMESERIES',
            function: {
              name: 'Inline Function',
              description: '',
              schema: {},
              code: PROCESS_FUNCTION_TEMPLATE,
              pinLevel: 'UNPINNED',
            },
          },
        });
        break;
      case 'Sink':
        setValue({
          inputType: 'TIMESERIES',
          outputType: 'TIMESERIES',
          function: {
            name: 'Inline Sink',
            description: '',
            schema: {},
            code: SINK_FUNCTION_TEMPLATE,
            pinLevel: 'UNPINNED',
          },
        });
        break;
    }
  };

  const handleAddFromExistingFunction = (fn: TypedFunction) => {
    switch (type) {
      case 'Source':
        setValue(fn.function.id);
        break;
      case 'Process':
        const existingProcesses = (props as Extract<DataflowNewFunctionProps, { type: 'Process' }>).getValues(
          'processes'
        );
        setValue({
          sequence: existingProcesses.length + 1,
          args: {},
          function: fn.function.id,
        });
        break;
      case 'Sink':
        setValue(fn.function.id);
        break;
    }
  };

  return (
    <div className="mr-1 border border-dashed rounded-md bg-transparent border-gray-200 dark:border-gray-500 px-4 py-8 flex flex-col items-center justify-center space-y-2">
      <p className="text-base my-0 font-semibold">Add Function</p>
      <div className="flex space-x-2 items-center justify-center">
        <Button onClick={handleNewInlineFunction}>Inline function</Button>
        <DataflowAddFromExistingFunctionButton
          typedFunctions={typedFunctions.filter((fn) => !fn.function.dataflowId)}
          onSelected={handleAddFromExistingFunction}
        />
      </div>
    </div>
  );
};

export default DataflowNewFunction;
