import React, { useEffect } from 'react';
import {
  Card,
  Field,
  FieldSet,
  Form,
  FormAPI,
  HorizontalGroup,
  Icon,
  IconButton,
  InputControl,
  RadioButtonGroup,
  ReactMonacoEditor,
  useTheme2,
} from '@grafana/ui';
import { css, cx } from '@emotion/css';
import { DragControls } from 'framer-motion';

export type DataflowInlineFunctionFormValues = {
  inputType: 'TIMESERIES' | 'FREQUENCY';
  outputType: 'TIMESERIES' | 'FREQUENCY';
  code: string;
};

type DataflowInlineFunctionProps = {
  type: 'Source' | 'Process' | 'Sink';
  value: DataflowInlineFunctionFormValues;
  setValue: (value: DataflowInlineFunctionFormValues) => void;
  onDelete: () => void;
  controls?: DragControls;
};

const DataflowInlineFunction: React.FC<DataflowInlineFunctionProps> = ({
  type,
  value,
  setValue,
  onDelete,
  controls,
}) => {
  const handleSubmit = (_data: typeof value) => {};

  return (
    <Form onSubmit={handleSubmit} defaultValues={value} className="w-full">
      {(props) => (
        <DataflowInlineFunctionForm
          {...props}
          type={type}
          onValueChange={setValue}
          onDelete={onDelete}
          controls={controls}
        />
      )}
    </Form>
  );
};

const DataflowInlineFunctionForm: React.FC<
  FormAPI<DataflowInlineFunctionFormValues> & {
    type: 'Source' | 'Process' | 'Sink';
    onValueChange: (value: DataflowInlineFunctionFormValues) => void;
    onDelete: () => void;
    controls?: DragControls;
  }
> = ({ type, register, errors, control, watch, onValueChange, onDelete, controls }) => {
  const theme = useTheme2();

  useEffect(() => {
    const { unsubscribe } = watch((value) => {
      onValueChange(value);
    });
    return () => {
      try {
        unsubscribe();
      } catch {}
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <div
      className={cx(
        'w-full p-4 rounded-md relative',
        css({
          backgroundColor: theme.colors.background.secondary,
        })
      )}
    >
      <div className="absolute right-4 top-4 flex space-x-4 items-center">
        {controls && (
          <Icon name="draggabledots" size="lg" className="cursor-grab" onPointerDown={(e) => controls.start(e)} />
        )}
        <IconButton
          name="trash-alt"
          size="lg"
          variant="destructive"
          tooltip="Delete"
          tooltipPlacement="left"
          onClick={onDelete}
        />
      </div>
      <FieldSet>
        <HorizontalGroup spacing="lg">
          {type !== 'Source' && (
            <Field label="Input Type" invalid={!!errors.inputType} error={errors.inputType?.message}>
              <InputControl
                control={control}
                name="inputType"
                render={({ field }) => (
                  <RadioButtonGroup
                    {...field}
                    options={[
                      { label: 'Timeseries', value: 'TIMESERIES' },
                      { label: 'Frequency', value: 'FREQUENCY' },
                    ]}
                  />
                )}
              />
            </Field>
          )}
          <Field label="Output Type" invalid={!!errors.outputType} error={errors.outputType?.message}>
            <InputControl
              control={control}
              name="outputType"
              render={({ field }) => (
                <RadioButtonGroup
                  {...field}
                  options={[
                    { label: 'Timeseries', value: 'TIMESERIES' },
                    { label: 'Frequency', value: 'FREQUENCY' },
                  ]}
                />
              )}
            />
          </Field>
        </HorizontalGroup>
        <Field label="Code (Python)" invalid={!!errors.code} error={errors.code?.message} required>
          <InputControl
            control={control}
            name="code"
            rules={{
              required: 'Code is required',
            }}
            render={({ field }) => (
              <ReactMonacoEditor
                {...field}
                height={Math.max(field.value?.split('\n').length ?? 0, 5) * 19 + 12}
                language="python"
                options={{
                  minimap: { enabled: false },
                  scrollBeyondLastLine: false,
                  scrollbar: {
                    alwaysConsumeMouseWheel: false,
                  },
                }}
              />
            )}
          />
        </Field>
      </FieldSet>
    </div>
  );
};

export default DataflowInlineFunction;
