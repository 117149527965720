import { Badge, Button, Card } from '@grafana/ui';
import React, { useState } from 'react';
import { TypedFunction } from 'types';
import FunctionEditDrawer from './FunctionEditDrawer';
import useConfirm from '../../hooks/useConfirm';
import { DELETE } from '../../client';

interface FunctionListItemProps {
  fn: TypedFunction;
}

const FunctionListItem: React.FC<FunctionListItemProps> = ({ fn }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [DeleteFunctionModal, handleDelete] = useConfirm({
    title: `Delete Function '${fn.function.name}'`,
    body: 'Are you sure you want to delete this function?',
    confirmText: 'Delete',
    onConfirm: async () => {
      try {
        await DELETE('/api/functions/{id}', {
          params: {
            path: {
              id: fn.function.id,
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <>
      <Card>
        <Card.Heading>
          {fn.function.name}
          <Card.Tags>
            {fn.function.pinLevel === 'PINNED' && <Badge text="Pinned" color="green" className="mr-2" />}
            {fn.function.pinLevel === 'SYSTEM' && <Badge text="System" color="red" className="mr-2" />}
            {fn.__typename === 'Source' && <Badge text="Source" color="blue" />}
            {fn.__typename === 'Process' && <Badge text="Process" color="purple" />}
            {fn.__typename === 'Sink' && <Badge text="Sink" color="red" />}
          </Card.Tags>
        </Card.Heading>
        <Card.Description>{fn.function.description}</Card.Description>
        <Card.Meta>
          {fn.__typename === 'Source'
            ? [`Output: ${fn.outputType}`]
            : [`Input: ${fn.inputType}`, `Output: ${fn.outputType}`]}
        </Card.Meta>
        <Card.Actions>
          <Button key="settings" variant="secondary" icon="edit" onClick={() => setIsOpen(true)}>
            Edit
          </Button>
          <Button key="explore" variant="destructive" icon="trash-alt" onClick={() => handleDelete()}>
            Delete
          </Button>
        </Card.Actions>
        {isOpen && <FunctionEditDrawer fn={fn} onClose={() => setIsOpen(false)} />}
      </Card>
      {DeleteFunctionModal}
    </>
  );
};

export default FunctionListItem;
