import { css, cx } from '@emotion/css';
import { ClickOutsideWrapper, ColorPicker, Icon, IconButton, Input, Portal, useStyles2, useTheme2 } from '@grafana/ui';
import { AnimatePresence, LayoutGroup, m } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';
import useConfirm from '../hooks/useConfirm';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { components } from 'api';
import { WS_API_URL } from 'common';
import ChannelMenu from './ChannelMenu';
import { colorManipulator, GrafanaTheme2 } from '@grafana/data';
import { usePopper } from 'react-popper';
import { Popover } from '@headlessui/react';
import stringHash from 'string-hash';
import { DELETE, PUT } from '../client';
import useObservable from '../hooks/useObservable';
import { appState } from './SimplePanel';
import { distinct, map } from 'rxjs';

type Channel = components['schemas']['Channel'];
type ChannelGroup = components['schemas']['ChannelGroup'];

const getStyles = (theme: GrafanaTheme2) => {
  return {
    channelItem: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 4,
      '&:hover': {
        backgroundColor: theme.colors.emphasize(theme.colors.background.canvas, 0.1),
      },
    }),
    activeChannelItem: css({
      backgroundColor: theme.colors.emphasize(theme.colors.background.canvas, 0.1),
    }),
  };
};

type ChannelGroupWithChannels = ChannelGroup & {
  channels: Channel[];
  immutable?: boolean;
};

const getColors = (theme: GrafanaTheme2) =>
  theme.visualization.palette.filter(
    (color) =>
      colorManipulator.getContrastRatio(theme.visualization.getColorByName(color), theme.colors.background.primary) >=
      theme.colors.contrastThreshold
  );

const ChannelGroups: React.FC<{
  height: number;
  reducedMotion: 'user' | 'always';
  onChannelGroupSelected: (channelGroup: ChannelGroup) => void;
}> = ({ height, reducedMotion, onChannelGroupSelected }) => {
  const theme = useTheme2();
  const colors = useMemo(() => getColors(theme), [theme]);
  const [channels, setChannels] = React.useState<Channel[]>([]);
  const [channelGroups, setChannelGroups] = React.useState<ChannelGroupWithChannels[]>([]); // data.series[0].fields[0].values[0
  const [groupedChannels, setGroupedChannels] = React.useState<ChannelGroupWithChannels[]>([]);
  const [expanded, setExpanded] = React.useState<Record<number, boolean>>({ 0: true });
  const [hiddenChannels, setHiddenChannels] = React.useState<string[]>([]);
  const groupNameEditing = useObservable(
    appState.pipe(
      distinct(({ groupNameEditing }) => groupNameEditing),
      map((x) => x.groupNameEditing)
    ),
    null
  );
  const [ConfirmDeleteModal, openConfirmDeleteModal] = useConfirm<{
    groupId: number;
  }>({
    title: 'Delete Channel Group',
    body: 'Are you sure you want to delete this channel group?',
    confirmText: 'Delete',
    onConfirm: async (props) => {
      if (!props) {
        return;
      }
      await DELETE('/api/channel-groups/{id}', {
        params: {
          path: { id: props.groupId },
        },
      });
    },
  });

  useEffect(() => {
    const ws = new ReconnectingWebSocket(`${WS_API_URL}/api/channels/watch`);
    const ws2 = new ReconnectingWebSocket(`${WS_API_URL}/api/channel-groups/watch`);

    ws.addEventListener('message', (event) => {
      setChannels(JSON.parse(event.data) as Channel[]);
    });

    ws2.addEventListener('message', (event) => {
      setChannelGroups(JSON.parse(event.data) as ChannelGroupWithChannels[]);
    });

    return () => {
      ws.close();
      ws2.close();
    };
  }, []);

  useEffect(() => {
    // move channel without group to default group

    const groups = [
      { id: 0, name: 'Default Group', channels: [] as Channel[], immutable: true },
      ...channelGroups.map((group) => ({ ...group, channels: [] as Channel[] })),
    ];

    channels.forEach((channel) => {
      if (channel.groups.length === 0) {
        groups[0].channels.push(channel);
        return;
      }
      for (const chGroup of channel.groups) {
        const group = groups.find((group) => group.id === chGroup);
        if (group) {
          group.channels.push(channel);
        }
      }
    });

    setExpanded(groups.reduce((acc, group) => ({ ...acc, [group.id]: true }), {} as Record<number, boolean>));

    setGroupedChannels(groups);
  }, [channelGroups, channels]);

  useEffect(() => {
    setHiddenChannels(channels.filter((channel) => channel.plottingAttributes.hidden).map((channel) => channel.name));
  }, [channels]);

  return (
    <>
      <LayoutGroup>
        {groupedChannels.map((group) => (
          <React.Fragment key={group.id}>
            <m.div
              layout={reducedMotion === 'user' ? 'position' : false}
              role="button"
              className="flex items-center p-2"
              onClick={() => setExpanded({ ...expanded, [group.id]: !expanded[group.id] })}
            >
              <div className="flex items-center">
                {groupNameEditing === group.id ? (
                  <ClickOutsideWrapper
                    onClick={() => {
                      appState.next({ ...appState.getValue(), groupNameEditing: null });
                    }}
                  >
                    <form
                      onSubmit={async (ev) => {
                        ev.preventDefault();
                        appState.next({ ...appState.getValue(), groupNameEditing: null });
                        await PUT('/api/channel-groups/{id}', {
                          params: {
                            path: { id: group.id },
                          },
                          body: group,
                        });
                      }}
                    >
                      <Input
                        value={group.name}
                        onChange={(ev) => {
                          group.name = ev.currentTarget.value;
                          setGroupedChannels([...groupedChannels]);
                        }}
                        onBlur={async () => {
                          appState.next({ ...appState.getValue(), groupNameEditing: null });
                          await PUT('/api/channel-groups/{id}', {
                            params: {
                              path: { id: group.id },
                            },
                            body: group,
                          });
                        }}
                      />
                    </form>
                  </ClickOutsideWrapper>
                ) : (
                  <>
                    <p
                      className={css({
                        margin: 0,
                        fontWeight: 600,
                      })}
                    >
                      {group.name} ({group.channels.length})
                    </p>
                    {!group.immutable && (
                      <IconButton
                        name="pen"
                        className={css({ marginLeft: 8 })}
                        size="sm"
                        onClick={(ev) => {
                          ev.stopPropagation();
                          appState.next({ ...appState.getValue(), groupNameEditing: group.id });
                        }}
                      />
                    )}
                  </>
                )}
              </div>
              <div className="flex items-center ml-auto mr-0">
                {groupNameEditing !== group.id && (
                  <>
                    {!group.immutable && (
                      <>
                        <IconButton
                          name="sliders-v-alt"
                          size="sm"
                          className="mr-2"
                          onClick={(ev) => {
                            ev.stopPropagation();
                            onChannelGroupSelected({
                              id: group.id,
                              name: group.name,
                            });
                          }}
                        />
                        <IconButton
                          name="trash-alt"
                          size="sm"
                          className="mr-2"
                          onClick={async (event) => {
                            event.stopPropagation();
                            openConfirmDeleteModal({ confirmProps: { groupId: group.id } });
                          }}
                        />
                      </>
                    )}
                    <IconButton
                      name={
                        group.channels.every((channel) => hiddenChannels.includes(channel.name)) ? 'eye-slash' : 'eye'
                      }
                      size="sm"
                      style={{ marginRight: 8 }}
                      onClick={async (event) => {
                        event.stopPropagation();
                        if (group.channels.every((channel) => hiddenChannels.includes(channel.name))) {
                          for (const channel of group.channels) {
                            channel.plottingAttributes.hidden = false;
                          }
                          await PUT('/api/channels', {
                            body: group.channels,
                          });
                        } else {
                          for (const channel of group.channels) {
                            channel.plottingAttributes.hidden = true;
                          }
                          await PUT('/api/channels', {
                            body: group.channels,
                          });
                        }
                      }}
                    />
                  </>
                )}
                <Icon
                  name="angle-down"
                  size="xl"
                  style={{
                    transform: expanded[group.id] ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                  }}
                />
              </div>
            </m.div>
            <AnimatePresence mode="popLayout">
              {expanded[group.id] && (
                <m.div
                  key={group.id}
                  layout={reducedMotion === 'user'}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  style={{
                    maxHeight: height - 32,
                    overflow: 'auto',
                    paddingBottom: 4,
                  }}
                >
                  {group.channels.map((channel) => (
                    <m.div layout={reducedMotion === 'user'} key={channel.name}>
                      <ChannelItem
                        channel={channel}
                        setChannels={setChannels}
                        hiddenChannels={hiddenChannels}
                        colors={colors}
                        groups={channelGroups}
                      />
                    </m.div>
                  ))}
                </m.div>
              )}
            </AnimatePresence>
          </React.Fragment>
        ))}
      </LayoutGroup>
      {ConfirmDeleteModal}
    </>
  );
};

const ChannelItem: React.FC<{
  channel: Channel;
  setChannels: React.Dispatch<React.SetStateAction<Channel[]>>;
  hiddenChannels: string[];
  colors: string[];
  groups: ChannelGroup[];
}> = ({ channel, setChannels, hiddenChannels, colors, groups }) => {
  const styles = useStyles2(getStyles);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>();
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right-start',
  });

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className={cx(styles.channelItem, open && styles.activeChannelItem)}
            as="div"
            role="button"
          >
            <div className="flex items-center">
              <div
                onClick={(ev) => {
                  ev.stopPropagation();
                }}
                className={css({
                  '& button': { width: 14, height: 14 },
                })}
              >
                <ColorPicker
                  color={
                    (channel.plottingAttributes.color as string) ||
                    colors[Math.abs(stringHash(channel.name)) % colors.length]
                  }
                  onChange={async (color) => {
                    channel.plottingAttributes.color = color;
                    setChannels((channels) => [...channels]);
                    await PUT('/api/channels/{name}', {
                      params: {
                        path: { name: channel.name },
                      },
                      body: channel,
                    });
                  }}
                />
              </div>
              <p className="m-0 ml-2">{channel.name}</p>
            </div>
            <div className="flex items-center">
              <IconButton
                name={hiddenChannels.includes(channel.name) ? 'eye-slash' : 'eye'}
                size="sm"
                style={{ marginRight: 8 }}
                onClick={async (event) => {
                  event.stopPropagation();
                  channel.plottingAttributes.hidden = !hiddenChannels.includes(channel.name);
                  await PUT('/api/channels/{name}', {
                    params: {
                      path: { name: channel.name },
                    },
                    body: channel,
                  });
                }}
              />
              <Icon name="angle-right" size="sm" />
            </div>
          </Popover.Button>
          <Portal>
            <Popover.Panel
              ref={(ref) => {
                setPopperElement(ref);
              }}
              style={popperStyles.popper}
              // className={css({
              //   zIndex: 1300,
              // })}
              {...attributes.popper}
            >
              <ChannelMenu channel={channel} groups={groups} colors={colors} />
            </Popover.Panel>
          </Portal>
        </>
      )}
    </Popover>
  );
};

export default ChannelGroups;
